<template>
    <div class="space-y-6 sm:space-y-5">
        <div class="flex flex-col">
            <autralis-input-group-comp label="New password"
                                       type="password"
                                       placeholder="New password"
                                       required
                                       @on-change="password = $event"
                                       :error="passwordsMatch === false ? 'Passwords do not match.' : null"
                                       :success="passwordsMatch === true ? 'Passwords match.' : null"
                                       :value="password"/>
        </div>
        <div>
            <autralis-input-group-comp label="Confirm password"
                                       type="password"
                                       placeholder="Confirm password"
                                       required
                                       @on-change="confirmPassword = $event"
                                       :error="passwordsMatch === false ? 'Passwords do not match.' : null"
                                       :success="passwordsMatch === true ? 'Passwords match.' : null"
                                       :value="confirmPassword"/>
        </div>
    </div>
</template>

<script>
import InputGroup from "@/components/input-group";
export default {
    name: "NewPassword",
    props: {
        saved: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            password: '',
            confirmPassword: '',
            passwordsMatch: null
        }
    },


    watch: {
        password() {
            this.comparePasswords();
        },
        confirmPassword() {
            this.comparePasswords();
        },
        passwordsMatch() {
            const confirmedPassword = this.password === this.confirmPassword ? this.password : ''
            this.$emit('on-change', confirmedPassword)
        },
        saved: function (newVal) {
            if (newVal) this.resetForm()
        }
    },

    methods: {
        resetForm() {
            this.password = ''
            this.confirmPassword = ''
            this.passwordsMatch = null
            this.passwordType = 'password'
            this.passwordConfirmType = 'password'
        },

        togglePasswordType(field) {
            this[field] = this[field] === 'password' ? 'text' : 'password';
        },

        comparePasswords() {
            if (this.password !== '' && this.confirmPassword !== '') {
                this.passwordsMatch = this.password === this.confirmPassword;
            }
        }
    },
    components: {
        'autralis-input-group-comp': InputGroup
    }
}
</script>

<style scoped>

</style>