<template>
    <form @submit.prevent="onSave" class="relative h-full p-6 w-full">
        <autralis-success-comp :show="saved" text="The password changed successfully."/>
        <autralis-error-comp :show="error" :text="error"/>
        <autralis-info-comp :show="noChangesMessage !== null" :text="noChangesMessage"/>


        <header class="space-y-1 py-2 px-4 bg-blue-700 rounded info-header sticky">
            <div class="flex items-center">
                <button type="submit"
                        class="primary-button">
                        <span v-if="saving" class="flex items-center">
                            <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Saving</translate> ...
                        </span>

                    <span v-if="!saving">Save</span>

                </button>
            </div>
        </header>

        <div class="flex w-full space-x-6 mt-6">
            <div class="w-full border-0 md:border border-gray-200 rounded pb-10 md:pt-10 px-0 md:px-6 lg:px-16 xl:px-28" style="max-width: 1024px;">
                <div class="text-left mb-9">
                    <h2 class="text-2xl md:text-3xl lg:text-4xl font-extrabold tracking-tight text-gray-900 ">
                        Change password
                    </h2>
                    <p class="mt-1 text-base md:text-lg leading-6 text-gray-500">
                        Confirm your old password and then change it.
                    </p>
                </div>

                <div class="border-b border-gray-200 pb-8 mb-8">
                    <autralis-current-password-comp @on-change="updateCurrentPassword" :saved="saved"/>
                </div>
                <autralis-new-password-comp @on-change="updateNewPassword" :saved="saved"/>
            </div>

        </div>

    </form>
</template>

<script>
import axios from "axios";
import ErrorBox from "@/components/error-box";
import SuccessBox from "@/components/success-box";
import InfoBox from "@/components/info-box";
import CurrentPassword from "./components/CurrentPassword";
import NewPassword from "./components/NewPassword";
import {INFO_BOX_TIMEOUT} from "../../../../constants";


export default {
    name: "PasswordDashboard",
    data() {
        return {
            currentPassword: '',
            newPassword: '',
            saving: null,
            error: null,
            saved: null,
            noChangesMessage: null
        }
    },

    computed: {
        saveDisabled() {
            return this.currentPassword === '' || this.newPassword === '';
        }
    },

    methods: {
        updateCurrentPassword(password) {
            this.currentPassword = password;
        },

        updateNewPassword(password) {
            this.newPassword = password;
        },

        async onSave() {
            if (!this.saveDisabled) {
                const data = {
                    currentPassword: this.currentPassword,
                    newPassword: this.newPassword
                }
                try {
                    this.saving = true;
                    this.saved = false;
                    this.error = null;
                    const response = await axios.post('/manager/expert3/xp3/update-password/', data, {withCredentials: true})
                    if (response.data.result === 'Ok') {
                        this.saved = true;
                    } else {
                        this.error = response.data.message

                    }

                    this.saving = false

                } catch (err) {
                    this.error = 'The password could not be updated. Please try again.'
                    this.saving = false
                }
            } else {
                this.noChangesMessage = 'Please fill all info in.';
                setTimeout(() => this.noChangesMessage = null, INFO_BOX_TIMEOUT)
            }

        },
    },

    components: {
        'autralis-error-comp': ErrorBox,
        'autralis-success-comp': SuccessBox,
        'autralis-info-comp': InfoBox,
        'autralis-current-password-comp': CurrentPassword,
        'autralis-new-password-comp': NewPassword,
    }
}
</script>

<style scoped>
.info-header {
    top: 0;
    z-index: 10
}

@media (max-width: 1023px) {
    .info-header {
        top: 157px;
    }
}
</style>